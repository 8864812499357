<template>
  <div>
    <v-form>
      <p class="mb-3">Consider the four molecules shown in the figure below:</p>

      <p class="mb-6 pl-8">
        <v-img src="/img/assignments/uciPL6Q2.png" max-width="293px" />
      </p>

      <p class="mb-4">
        What is the relationship between each of the indicated pairs of molecules below?
      </p>

      <p v-for="(question, index) in questions" :key="options[index].value" class="pl-8 mb-3">
        {{ question }}
        <v-select
          v-model="inputs[`input${index + 1}`]"
          :items="options"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'ChemUCI_51LPL6_Q2',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
      },
      questions: ['a) Molecules A and B:', 'b) Molecules A and C:', 'c) Molecules A and D:'],
      options: [
        {
          text: 'Constitutional Isomers',
          value: 'isomers',
        },
        {
          text: 'Diastereomers',
          value: 'diastereomers',
        },
        {
          text: 'Enantiomers',
          value: 'enantiomers',
        },
        {
          text: 'Identical Molecules',
          value: 'identical',
        },
      ],
    };
  },
};
</script>
